<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {
      colores: "",
    };
  },

  async created() {
    await this.getAllInfoClr("profile");

    this.colores = this.profiles;
    this.actualizarColores();
  },
  watch: {
    profiles() {
      this.actualizarColores();
    },
  },
  computed: {
    profiles() {
      return this.$store.getters["profile/data"];
    },
  },
  methods: {
    ...mapActions("colors", ["getAllInfoClr"]),
    ...mapActions("profile", ["getAllInfoPrf"]),
    actualizarColores() {
      const arr = this.$store.getters["colors/data"];

      if (Array.isArray(arr)) {
        for (var i = 0; i < arr.length; i++) {
          if (i == 0) {
            document.documentElement.style.setProperty(
              "--color-1",
              arr[i].code
            );
          } else if (i == 1) {
            document.documentElement.style.setProperty(
              "--color-2",
              arr[i].code
            );
          } else if (i == 2) {
            document.documentElement.style.setProperty(
              "--color-3",
              arr[i].code
            );
          } else if (i == 3) {
            document.documentElement.style.setProperty(
              "--color-4",
              arr[i].code
            );
          } else if (i == 4) {
            document.documentElement.style.setProperty(
              "--color-5",
              arr[i].code
            );
          } else if (i == 5) {
            document.documentElement.style.setProperty(
              "--color-6",
              arr[i].code
            );
          } else if (i == 6) {
            document.documentElement.style.setProperty(
              "--color-7",
              arr[i].code
            );
          } else if (i == 7) {
            document.documentElement.style.setProperty(
              "--color-7",
              arr[i].code
            );
          }
        }
      }
    },
  },
};
</script>

<style>
@import "./css/default.css";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');

html {
  scroll-behavior: smooth;
}

:root {
  /** No eliminar los 7 colores */
  --color-1: #0055b8;
  --color-2: #ffc844;
  --color-3: #ffffff;
  --color-4: #f5f5f5;
  --color-5: #a7a7a7;
  --color-6: #d9d9d9;
  --color-7: #ffffff;
  --opacity: rgba(255, 255, 255, 0.6);
}

#app {
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0vw;
}

body {
  margin: 0vw;
}

.dflxc {
  display: flex;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}

.dflx {
  display: flex;
}

.dflxa {
  display: flex;
  align-items: center;
}

.dflxca {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dflxsp {
  display: flex;
  justify-content: space-between;
}

.porel {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.jcsb {
  justify-content: space-between;
}

.box_ctr {
  display: flex;
  justify-content: center;
}

.box_ctr_alg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crs_ptr {
  cursor: pointer;
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
  background-color: #F4F4F4;
  /** border-radius: 1.0416666666666665VW;  */
}

body::-webkit-scrollbar-button:increment,
body::-webkit-scrollbar-button {
  display: none;
}

body::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

body::-webkit-scrollbar-thumb {
  background-color: #87b5ed;
  /** border-radius: 1.0416666666666665VW;  */
  border: 0.10416666666666667vw solid #87b5ed;
}

body::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

.cursorp {
  cursor: pointer;
}

p.err_msg {
  color: #A1D469 !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.carg_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .7vw;


}

.carg_btns p:first-child {
  margin-right: 1vw;
}

.brdr {
  height: 2px;
  background-color: #ccc;
  width: 70.7vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
}

p.subttl {
  color: var(--color-4);
  font-weight: 600;
  font-size: 1.3020833333333335vw;
  font-family: 'DM Sans';
  margin: 0vw;
  margin-bottom: 1vw;
}

.carg_btns p {
  background-color: #87b5ed;
  border-radius: 1.5vw;
  height: 2vw;
  margin: 0vw;
  letter-spacing: 3px;
  font-family: var(--DMSans);
  font-size: .7vw;
  width: 14vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carg_btns p img {
  width: 2.5vw;
}

.tbl_min {
  width: fit-content;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.mrtp {
  margin-top: 1vw !important;
}

.tbl_min_hdr {
  display: flex;
  align-items: center;
}

.tbl_min_hdr p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  color: #ffffff;
  font-family: 'DM Sans';
  background-color: var(--color-1);
  border-right: 1px solid white;
  margin: 0vw;
  padding: .3vw 0vw;
}

.tbl_min_row {
  display: flex;
  align-items: center;
  margin-top: .5vw;
}

.tbl_min_row p {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid white;
  font-size: .8vw;
  font-family: 'DM Sans';
  margin: 0vw;
}

p.tblm1 {
  width: 15vw;
}

p.tblm2 {
  width: 6vw;
}

p.tblm3 {
  width: 10vw;
}

p.tblm4 {
  width: 6vw;
}

p.tblm5 {
  width: 6vw;
}

p.tblm6 {
  width: 8vw;
}

p.tblm7 {
  width: 8vw;
}

p.tblm7 img {
  cursor: pointer;
  width: .8vw;
}

p.bg-grey {
  background-color: #242424;
}
</style>
